<template>
    <div>
        <el-drawer title="网址追踪设置"  
            :visible="webConfigShow" 
            direction="rtl" 
            @close="close" 
            wrapperClosable 
            size="1200px"
            :append-to-body="true"
            :modal-append-to-body="false"
            @open="init">
            <div class="drawerCon" v-if="webConfigShow">
                <div class="campainCon">
                    <div>
                        <el-input type='textarea' rows="6" :value="inputValue" readonly ></el-input>
                    </div>
                    <el-tabs v-model="activeName">
                        <!-- <el-tab-pane label="Google Analytics参数设置" name="first">
                            <el-form v-model="settingParams" label-position="top">
                                <el-form-item label="from(平台)">
                                    <el-select v-model="settingParams.from.val" multiple placeholder="请选择" style="width:60%" disabled>
                                        <el-option
                                        v-for="item in dictsInit.utm"
                                        :key="item.label"
                                        :label="item.label"
                                        :value="item.label">
                                        </el-option>
                                    </el-select>
                                    <el-select v-model="settingParams.utm_source.symbol" placeholder="请选择"  style="width:60px" disabled>
                                        <el-option :label="symbol.key" :value="symbol.val" v-for="(symbol,index) in symbolList" :key="index"></el-option>
                                    </el-select>

                                </el-form-item>
                                <el-form-item label="opt_id(当前优化师ID)">
                                    <el-select v-model="settingParams.opt_id.val" multiple placeholder="请选择" style="width:60%" disabled>
                                        <el-option
                                        v-for="item in dictsInit.utm"
                                        :key="item.label"
                                        :label="item.label"
                                        :value="item.label">
                                        </el-option>
                                    </el-select>
                                    <el-select v-model="settingParams.utm_source.symbol" placeholder="请选择"  style="width:60px" disabled>
                                        <el-option :label="symbol.key" :value="symbol.val" v-for="(symbol,index) in symbolList" :key="index"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="utm content(内容)">
                                    <el-select v-model="settingParams.utm_content.val" multiple placeholder="请选择" style="width:60%" disabled>
                                        <el-option
                                        v-for="item in dictsInit.utm"
                                        :key="item.label"
                                        :label="item.label"
                                        :value="item.label">
                                        </el-option>
                                    </el-select>
                                    <el-select v-model="settingParams.utm_content.symbol" placeholder="请选择"  style="width:60px" disabled>
                                        <el-option :label="symbol.key" :value="symbol.val" v-for="(symbol,index) in symbolList" :key="index"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="utm source(来源)">
                                    <el-select v-model="settingParams.utm_source.val" multiple placeholder="请选择" style="width:60%">
                                        <el-option
                                        v-for="item in dictsInit.utm"
                                        :key="item.label"
                                        :label="item.label"
                                        :value="item.label">
                                        </el-option>
                                    </el-select>
                                    <el-select v-model="settingParams.utm_source.symbol" placeholder="请选择"  style="width:60px">
                                        <el-option :label="symbol.key" :value="symbol.val" v-for="(symbol,index) in symbolList" :key="index"></el-option>
                                    </el-select>
                                    <p v-if="dictsInit.utm">
                                        <span v-for="item in dictsInit.utm.filter(v=>settingParams.utm_source.val.every(k=>k!=v.label))" :key="item.label" @click="quickSelect('utm_source',item.label)">{{item.label}}</span>
                                    </p>
                                </el-form-item>
                                <el-form-item label="utm medium(媒介)">
                                    <el-select v-model="settingParams.utm_medium.val" multiple placeholder="请选择" style="width:60%">
                                        <el-option
                                        v-for="item in  dictsInit.utm"
                                        :key="item.label"
                                        :label="item.label"
                                        :value="item.label">
                                        </el-option>
                                    </el-select>
                                    <el-select v-model="settingParams.utm_medium.symbol" placeholder="请选择"  style="width:60px">
                                        <el-option :label="symbol.key" :value="symbol.val" v-for="(symbol,index) in symbolList" :key="index"></el-option>
                                    </el-select>
                                    <p v-if="dictsInit.utm">
                                        <span v-for="item in  dictsInit.utm.filter(v=>settingParams.utm_medium.val.every(k=>k!=v.label))" :key="item.label"  @click="quickSelect('utm_medium',item.label)">{{item.label}}</span>
                                    </p>
                                </el-form-item>
                                <el-form-item label="utm campaign(名称)">
                                    <el-select v-model="settingParams.utm_campaign.val" multiple placeholder="请选择" style="width:60%">
                                        <el-option
                                        v-for="item in  dictsInit.utm"
                                        :key="item.label"
                                        :label="item.label"
                                        :value="item.label">
                                        </el-option>
                                    </el-select>
                                    <el-select v-model="settingParams.utm_campaign.symbol" placeholder="请选择"  style="width:60px">
                                        <el-option :label="symbol.key" :value="symbol.val" v-for="(symbol,index) in symbolList" :key="index"></el-option>
                                    </el-select>
                                    <p v-if="dictsInit.utm">
                                        <span v-for="item in  dictsInit.utm.filter(v=>settingParams.utm_campaign.val.every(k=>k!=v.label))" :key="item.label"  @click="quickSelect('utm_campaign',item.label)">{{item.label}}</span>
                                    </p>
                                </el-form-item>
                                <el-form-item label="utm term(关键词)">
                                    <el-select v-model="settingParams.utm_term.val" multiple placeholder="请选择" style="width:60%">
                                        <el-option
                                        v-for="item in  dictsInit.utm"
                                        :key="item.label"
                                        :label="item.label"
                                        :value="item.label">
                                        </el-option>
                                    </el-select>
                                    <el-select v-model="settingParams.utm_term.symbol" placeholder="请选择"  style="width:60px">
                                        <el-option :label="symbol.key" :value="symbol.val" v-for="(symbol,index) in symbolList" :key="index"></el-option>
                                    </el-select>
                                    <p v-if="dictsInit.utm">
                                        <span v-for="item in  dictsInit.utm.filter(v=>settingParams.utm_term.val.every(k=>k!=v.label))" :key="item.label" @click="quickSelect('utm_term',item.label)">{{item.label}}</span>
                                    </p>
                                </el-form-item>
                                
                            </el-form>
                        </el-tab-pane> -->
                        <el-tab-pane label="自定义设置" name="first">
                            <p class="title">网站地址后缀</p>
                            <!-- <div class="paramsList">
                                <div class="paramsItem" v-for="(custom,i) in customList" :key="i">
                                    <el-input placeholder="变量名" v-model="custom.key"></el-input>
                                    <span> = </span>
                                    <el-select v-model="custom.val"  filterable  allow-create  default-first-option placeholder="请选择变量名变量或直接输入">
                                        <el-option  v-for="item in dictsInit.utm" :key="item.label" :label="item.label" :value="item.label"> </el-option>
                                    </el-select>
                                    <i class="el-icon-remove-outline" @click="removeCustom(i)"></i>
                                </div>
                            </div> 
                            <el-button type="text" @click="addCustom">添加</el-button>-->
                            <web-url-config :initData="{tplStr:accountSet.gg_analytics,cusStr:`${accountSet.url_tags}`,cusData: []}" :currentTag="accountSet.url_tags" :selDatas="selDatas" ref="webConfig" @customSetEvent="customSetEvent" :showLabel="false"></web-url-config>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            <div class="demo-drawer__footer">
                <p>
                    <el-checkbox v-model="saveMine">保存为广告账号默认网址追踪 
                        <el-tooltip class="item" effect="dark" content="保存为广告账号默认网址追踪，下次该广告账号创建广告系列时，系统默认使用此网址追踪。你可以在广告资产中分别对各广告账号设置网址追踪。" placement="top-start"><i class="el-icon-warning-outline"></i></el-tooltip>
                    </el-checkbox>
                </p>
                <div>
                    <el-button @click="close">取消</el-button>
                    <el-button type="primary" @click="sure">保存</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import {getSetsByAccounts,getDicts,setContent} from '@/api/creatAd.js'
import webUrlConfig from '@/views/components/webUrlConfig.vue'
    export default {
        props:['webConfigShow','dictsInit','fbAccountId','accountSet'],
        data() {
            return {
                activeName:'first',
                settingParams:{
                    utm_source:{
                        val:[],
                        symbol:'_'
                    },
                    utm_medium:{
                        val:[],
                        symbol:'_'
                    },
                    utm_campaign:{
                        val:[],
                        symbol:'_'
                    },
                    utm_term:{
                        val:[],
                        symbol:'_'
                    },
                    utm_content:{
                        val:['广告系列ID'],
                        symbol:'_'
                    },
                    from:{
                        val:['facebook'],
                        symbol:'_'
                    },
                    opt_id:{
                        val:['当前优化师ID'],
                        symbol:'_'
                    },
                },
                symbolList:[{key:'_',val:'_'},{key:'-',val:'-'},{key:' ',val:' '},{key:'/',val:'/'},{key:'#',val:'#'},{key:'*',val:'*'}],
                paramsString:'',
                campainInfo:{

                },
                customList:[],
                saveMine:false,
                customSet:'',
                setFlag:false
            }
        },
        components:{
            webUrlConfig
        },
        computed:{
            selDatas(){
                return this.dictsInit?.utm.map(v=>v.label) || []
            },
            tags(){
                let list = []
                this.customList.map(item=>{
                    list.push(item.key+'='+item.val)
                })
                return this.customSet
            },
            inputValue(){
                return this.tags?this.paramsString + '&' + this.tags:this.paramsString
            },
            currentTag(){
                return (this.setFlag?this.customSet:this.accountSet.url_tags)
            }
            
        },
        methods: {
            // 关闭弹窗
            close(){
                // this.settingParams = {
                //     utm_source:{
                //         val:[],
                //         symbol:'_'
                //     },
                //     utm_medium:{
                //         val:[],
                //         symbol:'_'
                //     },
                //     utm_campaign:{
                //         val:[],
                //         symbol:'_'
                //     },
                //     utm_term:{
                //         val:[],
                //         symbol:'_'
                //     },
                //     utm_content:{
                //         val:[],
                //         symbol:'_'
                //     }
                // }
                // this.customList = []
                this.$emit('close','webConfigShow')
            },
            // defaultParams(){
            //     let list = [];
            //     this.tags.split('&').forEach((item,index) =>{
            //         list.push({
            //             key: item.split('=')[0] || '',
            //             value: item.split('=')[1] || ''
            //         })
            //     });
            //     return list
            // },
            init(){
                this.paramsString = this.accountSet.gg_analytics;
            },
            customSetEvent(v){
                this.setFlag = true;
                this.customSet = v
            },
            // 点击保存
            sure(){
                this.$refs.webConfig.$refs.formRef.validate(v=>{
                    console.log(v);
                    if(v){
                        // console.log(this.$refs.webConfig.createCusStrFn);
                        // this.$emit("confim-cus-param", {...this.initData,cusStr:this.initData.cusStr +'&'+this.$refs.webConfig.createCusStrFn});
                        // this.cusData = [];
                        this.$emit('setWebsitUrl',this.paramsString);
                        if(this.saveMine){
                            let user_tags = '';
                            let list = []
                            this.customList.map(item=>{
                                list.push(item.key+'='+item.val)
                            })
                            setContent({url_tags:this.tags,gg_analytics:this.paramsString,accounts:[this.fbAccountId]}).then(res=>{
                                if(res.code == 200){
                                    this.$message({
                                        type:'success',
                                        message:'保存成功'
                                    })
                                }
                            })
                        }
                        
                        this.close();
                    }
                })
                

            },
            // 添加自定义参数
            addCustom(){
                this.customList.push({key:'',val:''})
            },
            // 删除自定义参数
            removeCustom(index){
                this.customList.splice(index,1)
            },
            // 参数快捷选项
            quickSelect(key,val){
                this.settingParams[key].val.push(val);
                this.$set(this.settingParams,key,this.settingParams[key])
            }
            
        },
        watch: {
            settingParams:{
                handler(){
                    let paramsString = '';
                    // let utm_source = this.paramsList.utm_source.val.join(this.paramsList.utm_source.symbol);
                    // let utm_medium = this.paramsList.utm_medium.val.join(this.paramsList.utm_medium.symbol);
                    // let utm_campaign = this.paramsList.utm_campaign.val.join(this.paramsList.utm_campaign.symbol);
                    // let utm_term = this.paramsList.utm_term.val.join(this.paramsList.utm_term.symbol);
                    // let utm_content = this.paramsList.utm_content.val.join(this.paramsList.utm_content.symbol);
                    let settingParams = JSON.parse(JSON.stringify(this.settingParams))
                    let list = []
                    for(let key in settingParams){
                        if(settingParams[key].val.length){
                            // console.log(settingParams[key].val);
                            settingParams[key].val.map((item,i)=>{
                                settingParams[key].val[i] = `{${item}}`
                            })
                            list.push(key + '=' + settingParams[key].val.join(settingParams[key].symbol))
                        }
                    }
                    this.paramsString = list.join('&')
                },
                deep:true
            },
            accountSet:{
                handler(){
                    this.paramsString = this.accountSet.gg_analytics;
                    let str = this.accountSet.gg_analytics;
                    let list = str?str.split('&'):[];
                    // let 
                    let obj = {}
                    list.map(item=>{
                        let itemList = item.split('=')
                        let key = itemList[0],
                        val = itemList[1];
                        let symbol;
                        this.symbolList.map(v=>{
                            if(val.includes(v.key)){
                                // console.log(val.includes(v.key));
                                symbol = v.key
                            }
                        });
                        let value = val.split(symbol);
                        value.map((item,i)=>{
                            value[i] = item.slice(1,item.length-1)
                        })
                        obj[key] = {
                            val: value,
                            symbol:symbol+''
                        }
                    })
                    
                    
                    let url_tags = this.accountSet.url_tags;
                    let tags = url_tags?url_tags.split('&'):[];
                    let customerList = []
                    tags.map(item=>{
                        let itemsplit = item?item.split('='):[];
                        // console.log(itemsplit);
                        if(itemsplit.length){
                            customerList.push({key:itemsplit[0],val:itemsplit[1]})
                        }
                    })
                    this.customList = customerList;
                    if(!str){
                        this.settingParams = {
                            utm_source:{
                                val:[],
                                symbol:'_'
                            },
                            utm_medium:{
                                val:[],
                                symbol:'_'
                            },
                            utm_campaign:{
                                val:[],
                                symbol:'_'
                            },
                            utm_term:{
                                val:[],
                                symbol:'_'
                            },
                            utm_content:{
                                val:[],
                                symbol:'_'
                            },
                            utm_content:{
                                val:['广告系列ID'],
                                symbol:'_'
                            },
                            from:{
                                val:['facebook'],
                                symbol:'_'
                            },
                            opt_id:{
                                val:['当前优化师ID'],
                                symbol:'_'
                            },
                        }
                    }else{
                        this.settingParams = {...this.settingParams,...obj,...{utm_content:{ val:['广告系列ID'], symbol:'_' }}};
                    }
                }
            }
        },
    }
</script>
<style lang="scss" scope>
    .campainCon{
        padding: 0 20px;
        .title{
            line-height: 40px;
            color: #666;
            font-size: 14px;
        }
        .paramsList{
            .paramsItem{
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .el-input,.el-select{
                    width:360px;
                }
                span{
                    margin: 0 5px;
                    cursor: pointer;
                }
                i{
                    margin-left: 10px;
                    font-size: 18px;
                    color: #666;
                    cursor: pointer;
                }
            }
        }
        .el-input--suffix .el-input__inner{
            padding-right: 0;
        }
        .el-form-item{
            p{
                font-size: 12px;
                color: #999;
                span{
                    margin: 0 5px;
                    cursor: pointer;
                }
            }
        }
    }
</style>