import { render, staticRenderFns } from "./webConfig.vue?vue&type=template&id=56f4a5b0"
import script from "./webConfig.vue?vue&type=script&lang=js"
export * from "./webConfig.vue?vue&type=script&lang=js"
import style0 from "./webConfig.vue?vue&type=style&index=0&id=56f4a5b0&prod&lang=scss&scope=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports